import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/layouts/legal.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1><strong parentName="h1">{`EVENT SPACE TERMS AND CONDITIONS`}</strong></h1>
    <p>{`Capitalized terms used herein but not defined shall have the meanings ascribed to them in the Event Agreement or the Event Reservation Form which these terms and conditions are incorporated into by reference in their entirety.`}</p>
    <p><strong parentName="p">{`1. Event Space.`}</strong></p>
    <p>{` `}{` `}{` `}{` `}{`1.1 Spaces hereby grants to Host a limited and revocable license (the "License") to use the Event Space for the Event. Host understands and agrees that (i) the Location operates as a retail venue and is accessible to the general public during operating hours and (ii) during the Location’s operating hours, the general public may access the Event Space during the Total Event Time. As such, while Spaces will not permit another host to host an event in the Event Space during the Total Event Time, the use of the Location and Event Space by Host shall be non-exclusive, unless the Event Reservation Form specifically indicates the use of the Location will be exclusive.`}</p>
    <p>{` `}{` `}{` `}{` `}{`1.2 The License permits the Host to use the Event Space only for the Event on the Event Date during the Event Hours, subject to, and upon all of the terms, covenants, and conditions contained in this Agreement. The License shall not, under any circumstances, be coupled with an interest in the Event Space. Host acknowledges and agrees that no more than the Max Number of Guests will occupy the Event Space.`}</p>
    <p>{` `}{` `}{` `}{` `}{`1.3 The event may not include the sale of any goods or services, unless otherwise agreed by Spaces.`}</p>
    <p><strong parentName="p">{`2. Event Date and Event Time.`}</strong>{` Event Event Date and Event Time.  The Event shall be held on the Event Date during the Event Hours. Host will have access to the Event Space during the Total Event Time, which includes set up and take down time. Host, Host's Caterer, if any (hereinafter defined), and Host's other independent contractors, contracted vendors, and service providers retained by Host to provide services for the Event (collectively, "Host's Service Providers") shall not have access to the Event Space at any time other than during the Total Event Time, unless Host receives prior written authorization from Spaces.`}</p>
    <p><strong parentName="p">{`3. Deposit and Fees.`}</strong></p>
    <p>{` `}{` `}{` `}{` `}{`3.1 Host shall pay to Spaces the License Fee, Additional Fees and any other deposits and fees (collectively, the "Fees") determined in accordance with the Event Reservation Form.`}</p>
    <p>{` `}{` `}{` `}{` `}{`3.2 Spaces shall have no obligation under this Agreement until the Date-Hold Fee and the Security Deposit are paid in full, if applicable.`}</p>
    <p>{` `}{` `}{` `}{` `}{`3.3 If Host fails to pay any of the Fees by the applicable due dates set forth in the Event Reservation Form, Spaces shall have the right to revoke the License and retain the full amount of the Security Deposit (in addition to retaining the Date-Hold Fee).
`}{` `}{` `}{` `}{` `}{`
`}{` `}{` `}{` `}{` `}{`3.4 The Security Deposit shall be returned to Host within ten (10) business days after the Event Date if the Event Space is left in the same or similar condition as delivered to Host. If any repair and/or excessive cleaning is needed at the Event Space by reason of the Event to bring the Event Space to the same or similar condition as delivered to Host: (i) the Security Deposit will be applied by Spaces to the costs of such repair and/or cleaning upon written notice to Host of such application of the Security Deposit which notice shall include copies of invoices or other documentation sufficient to evidence such costs incurred by Spaces; (ii) any portion of the Security Deposit in excess of the application described in subparagraph (i) herein shall be returned to Host; and (iii) if the Security Deposit is insufficient to reimburse Spaces for such costs, Host shall be responsible for any deficiency, which shall be payable to Spaces within ten (10) business days after Host's receipt of written demand for same. This Section 3.4 shall survive termination of this Agreement.`}</p>
    <p>{` `}{` `}{` `}{` `}{`3.5 Unless otherwise agreed to by Spaces in writing, all Fees shall be payable in cash, check, or major credit card; provided, however, if payment of any Fees is made by major credit card, the processing fee charged by the credit company shall be charged to Host. Any refunds issued to Host will be returned less a handling fee.`}</p>
    <p><strong parentName="p">{`4. Condition of the Event Space.`}</strong>{`  Spaces shall have no obligation to deliver the Event Space in any condition other than as normally operated within the Location.`}</p>
    <p><strong parentName="p">{`5. Host's Caterer.`}</strong>{`  If Host is using its own caterer (`}<strong parentName="p">{`"Host's Caterer"`}</strong>{`) for the Event, Host's Caterer must clean the Event Space, the Catering Set-Up Area and all service areas within the Total Event Time, leaving it in the same condition and working order as at the start of the Event set up. Host's Caterer must remove all trash, composting, and recyclables from the Event Space, the Catering Set-Up Area and service areas. Failure to remove or clean the Event Space, Catering Set-Up Area, and service areas will result in additional fees being charged to Host and deducted from the Security Deposit.`}</p>
    <p><strong parentName="p">{`6. Sound System.`}</strong>{`  The Event Space sound system may be used for speech, recorded music, or live music, subject to available technology and features.  An Additional Fee may be charged.   `}</p>
    <p><strong parentName="p">{`7. Site Decoration.`}</strong>{`  If permitted by Spaces, in its sole discretion, arranging and moving furniture, artwork, lamps, seating, and other furnishings shall be performed solely by Spaces' employees. No nails, screws, staples, or penetrating items shall be used on walls, wood, or other fine surfaces. All tape and gummed backing materials shall be properly removed by Host and any damage to walls, wood, or other fine surfaces shall be repaired by Spaces, the cost of which shall be deducted from the Security Deposit or, if none, paid by Host promptly upon Spaces’ request.`}</p>
    <p><strong parentName="p">{`8. Conduct and Noise.`}</strong>{`  Smoking in the Event Space or in or within twenty-five (25) feet of the Event Space, loitering, disparaging remarks, physical violence, or illegal activities is not permitted and will not be tolerated. Host agrees to control the noise level at the Event such that it shall not create a disturbance. If Spaces reasonably determines that the noise level is excessive, upon request by Spaces, Host shall immediately reduce the volume. Conduct deemed disorderly and/or noise levels deemed excessive, at the sole discretion of Spaces, shall be grounds for immediate expulsion from the Event Space and the conclusion of the License. In such event, Host shall not be entitled to any refund of any Fees. `}</p>
    <p><strong parentName="p">{`9. Insurance.`}</strong>{`  Host, and Host's Caterer, if any, must each carry insurance evidencing event liability insurance that provides bodily injury and property damage insurance coverage for all bodily injury, property damage, personal injury, and other claims, losses, or damages arising out of or in connection with, in whole or in part, the use or occupancy of the Event Space, sidewalks, and other appurtenances to the Event Space, by Host, Host's Caterer (if any), Host's Service Providers (if any), or any other employee, agent, representative, or invitee of the Event. The insurance required hereunder shall have a single limit liability of not less than $1 Million and general aggregate liability of not less than $2 Million and shall name Spaces as an additional insured. If alcohol is served, the policy shall also include host liquor liability coverage.  Upon request, Host will provide Spaces with certificates evidencing the same. `}</p>
    <p><strong parentName="p">{`10. Compliance with Laws.`}</strong>{`  Host shall, and shall cause the Host's Caterer and the Host's Service Providers to, comply with all applicable laws and obtain any and all required permits and licenses necessary to use the Event Space. Host shall not, and shall not permit the Host's Caterer or the Host's Service Providers to, use the Event Space in any manner that would violate local, state, or federal laws or regulations related to Host's use of the Event Space. `}</p>
    <p><strong parentName="p">{`11. Indemnification.`}</strong>{`  Host hereby agrees to indemnify, defend, and hold harmless Spaces, its officers, directors, employees, and agents, from any and all damages, liabilities, actions, suits, claims, costs (including reasonable attorneys' fees), penalties, or expenses arising out of or in connection with, in whole or in part, directly or indirectly: (a) the occupancy or use of the Event Space, Location, Caterer Set-up Area, sidewalks, and other appurtenances to the Event Space, by Host, Host's Caterer (if any), Host's Service Providers (if any), or any other invitee of the Event; or (b) any acts, errors, or omissions on the part of Host, its officers, directors, employees, or agents, including, without limitation, Host's Caterer (if any) or any of Host's Service Providers. This Section 12 shall survive termination of this Agreement.`}</p>
    <p><strong parentName="p">{`12. Permitted Use and Capacity.`}</strong>{`  Host is authorized, pursuant to the License, to use the Event Space for the Event and for no other purpose, unless Spaces gives Host prior written authorization for additional permitted uses. Host shall not use the Event Space in any manner that may result in increased insurance premiums for Spaces with respect to the Event Space or render such insurance void. Host acknowledges and agrees that the maximum capacity of the Event Space is as set forth in the Event Reservation Form and Host will not exceed such limit. Failure to comply with this Section 12 shall be a breach of this Agreement and permit Spaces to revoke the License pursuant to Section 16 herein. `}</p>
    <p><strong parentName="p">{`13. Access and Right of Entry.`}</strong>{`  In addition to operating the retail venue at the Location during operating hours, Spaces shall have the right to enter the Event Space at any time, without the consent of Host, for any reasonable purpose, including any emergency that may threaten damage to the Event Space, or injury to any person in or near the Event Space.`}</p>
    <p><strong parentName="p">{`14. Cancellations.`}</strong>{`  Cancellation at any time by Host shall result in the forfeiture of the Security Deposit. `}</p>
    <p><strong parentName="p">{`16. Force Majeure.`}</strong>{`  In the event Spaces is unable, for reasons beyond its reasonable control, to make the Event Space available to Host on the Event Date for the purpose set forth in this Agreement, Host shall have the option of choosing an alternate date to hold the Event (the "Alternate Event Date"), at no extra charge to Host. If Host chooses an Alternate Event Date that is reasonably acceptable to Spaces, then the Alternate Event Date shall replace the Event Date for the purposes of this Agreement, and all rights and obligations under this Agreement shall remain binding on the parties hereto. If: (a) Host does not elect to choose an Alternate Event Date; or (b) Spaces and Host cannot agree on an Alternate Event Date ten (10) days of the original Event Date, this Agreement shall terminate and Spaces shall refund the Security Deposit and any portion of the Fees received by Spaces from Host hereunder. In neither instance shall Spaces be liable for any additional costs, expenses, or damages suffered by Host (over and above the Fees) arising out of the rescheduling or cancellation of the Event pursuant to this Section 15. `}</p>
    <p><strong parentName="p">{`16. Revocation of License.`}</strong>{`  Spaces shall have the right to revoke the License at any time prior to the Event Date provided Spaces provides written notice of such revocation to Host. Spaces' right of revocation shall be limited to the following events: (a) nonpayment of Fees by the applicable due dates; (b) breach of this Agreement by Host; or (c) the Event is of a nature that Spaces finds subjectively inappropriate or otherwise is in conflict with Spaces then-existing contractual obligations. If Spaces revokes the Agreement because of subsection (c) herein, Spaces shall refund the Security Deposit and such amount of the License Fees as has been paid to Spaces. In all other events, Spaces shall retain all amounts paid to Spaces (including but not limited to the Security Deposit) as liquidated damages and not as a penalty.`}</p>
    <p><strong parentName="p">{`17. Notices.`}</strong></p>
    <p>{` `}{` `}{`17.1 Any notice, authorization, or other communication required or otherwise given pursuant to this Agreement shall be in writing and shall be deemed sufficiently given: `}</p>
    <p>{` `}{` `}{` `}{` `}{`17.1.1 When delivered by hand (with written confirmation of receipt).`}</p>
    <p>{` `}{` `}{` `}{` `}{`17.1.2 Five (5) days after the date mailed, if sent by registered or certified mail, return receipt requested, postage prepaid. `}</p>
    <p>{` `}{` `}{` `}{` `}{`17.1.3 When received by the addressee, if sent by a nationally recognized overnight courier (receipt requested).`}</p>
    <p>{` `}{` `}{` `}{` `}{`17.1.4 On the date sent by email (with confirmation of transmission) if sent during normal business hours of the recipient, and on the next business day if sent after the normal business hours of the recipient. `}</p>
    <p>{` `}{` `}{`17.2 All notices and other communications shall be sent to the respective parties at the following addresses or at such other address for a party as shall be specified in a notice given in accordance with this Section 17 to the respective contact persons at the respective addresses set forth on the Event Reservation Form as the contact information for purposes of this Section 17, or, if none, as set forth on the Event Reservation Form.`}</p>
    <p><strong parentName="p">{`18 Assignment.`}</strong>{`  This Agreement may not be assigned by Host without the prior written consent of Spaces.`}</p>
    <p><strong parentName="p">{`19 Governing Law.`}</strong>{`  This Agreement shall be construed in accordance with, and governed in all respects by, the laws of the State of New York, without regard to conflicts of laws principles.`}</p>
    <p><strong parentName="p">{`20 Counterparts.`}</strong>{`  This Agreement may be executed in several counterparts, each of which shall constitute an original and all of which, when taken together, shall constitute one agreement.`}</p>
    <p><strong parentName="p">{`21 Severability.`}</strong>{`  If any one or more parts of this Agreement shall be held unenforceable for any reason, the remainder of this Agreement shall continue in full force and effect. If any provision of this Agreement is deemed invalid or unenforceable by any court of competent jurisdiction, and if limiting such provision would make the provision valid, then such provision shall be deemed to be construed as so limited. `}</p>
    <p><strong parentName="p">{`22 Headings.`}</strong>{`  The headings for each section herein are for convenience only and shall not affect the meaning of the provisions of this Agreement.`}</p>
    <p><strong parentName="p">{`23 Entire Agreement.`}</strong>{`  This Agreement constitutes the entire agreement between Spaces and Host, and supersedes any prior understanding or representation of any kind preceding the date of this Agreement. There are no other promises, conditions, understandings, or other agreements, whether oral or written, relating to the subject matter of this Agreement.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      